import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import useDeviceMedia from "../../hooks/useDeviceMedia";
import { useLocation } from "@reach/router";
import "./Pagination.scss";
import $ from "jquery";

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
  currentItems,
  selectOptions,
  allCategoryOption
}) => {
  const { isMobile } = useDeviceMedia();
  const location = useLocation();
  
  const [pageNumbersToShow, setPageNumbersToShow] = useState([1]);
  const [totalPageNumbers, setTotalPageNumbers] = useState(1);
  const [getCat, setGetCat] = useState('');
  const [getTab, setGetTab] = useState('');
  useEffect(() => {
    const catElement = document.getElementsByClassName("category-select__single-value")[0];
    const tabElement = document.querySelector(".category-tab.active");

    if (catElement) {
      setGetCat(catElement.innerHTML);
    }
    if (tabElement) {
      setGetTab(tabElement.innerHTML);
    }
  }, []);

  useEffect(() => {
    if (getCat || getTab) {
      setCurrentPage(1);
      const searchElement = document.querySelector(".form-control.search");
      if (searchElement) {
        searchElement.value = ''; // Clear search input value
      }
    }
  }, [getCat, getTab]);

  // Handle pagination logic
  useEffect(() => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    if (pageNumbers.length > 7) {
      const leftIndex = currentPage - 4;
      const rightIndex = currentPage + 3;
      if (leftIndex < 1) {
        setPageNumbersToShow(pageNumbers.slice(0, 7));
      } else if (rightIndex > pageNumbers.length) {
        setPageNumbersToShow(pageNumbers.slice(pageNumbers.length - 7, pageNumbers.length));
      } else {
        setPageNumbersToShow(pageNumbers.slice(leftIndex, rightIndex));
      }
    } else {
      setPageNumbersToShow(pageNumbers);
    }

    setTotalPageNumbers(pageNumbers.length);
  }, [currentPage, totalItems, itemsPerPage, getCat, getTab]);

  const handlePageChange = (value) => {
    $("html, body").animate({ scrollTop: isMobile ? 250 : 350 }, 800);

    if (typeof value === "object") {
      setCurrentPage(value.value);
    } else if (typeof value === "number") {
      setCurrentPage(value);
    } else {
      switch (value) {
        case "back":
          setCurrentPage(page => Math.max(1, page - 1));
          break;
        case "next":
          setCurrentPage(page => Math.min(totalPageNumbers, page + 1));
          break;
        default:
          break;
      }
    }
  };

  if (totalPageNumbers < 2) return null;

  return (
    <nav className="pagination-wrapper">
      <div className="pagination-container">
        <button
          onClick={() => handlePageChange("back")}
          className={`button button-secondary pagination-button button-back ${
            currentPage <= 1 ? "button-disabled" : ""
          }`}
          disabled={currentPage <= 1}
        >
          <i className="icon icon-arrow-left"></i>
          {!isMobile && <span>Back</span>}
        </button>

        {isMobile ? (
          <div className="pagination-select-wrap">
            <span className="page-text">Page:</span>
            <ReactSelect
              options={pageNumbersToShow.map(num => ({ value: num, label: num }))}
              isSearchable={false}
              className={"pagination-select"}
              classNamePrefix={"pagination-select"}
              value={{ value: currentPage, label: currentPage }}
              onChange={handlePageChange}
            />
            <span className="page-text">of {totalPageNumbers}</span>
          </div>
        ) : (
          <div className="pagination-numbers-wrap">
            {pageNumbersToShow.map(number => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`button button-secondary pagination-button ${
                  parseInt(currentPage) === number ? "button-active" : ""
                }`}
              >
                {number}
              </button>
            ))}
          </div>
        )}

        <button
          onClick={() => handlePageChange("next")}
          className={`button button-secondary pagination-button button-next ${
            currentPage >= totalPageNumbers ? "button-disabled" : ""
          }`}
          disabled={currentPage >= totalPageNumbers}
        >
          {!isMobile && <span>Next</span>}
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
