import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
import TeamCard from "../TeamCard/TeamCard"
import { useLocation } from "@reach/router"
import { parse as parseSearch } from "query-string"
import "./TeamListing.scss"
//import { useAutoAnimate } from "@formkit/auto-animate/react"
import _ from "lodash"
import useTeams from "../../hooks/useTeams"
import { sort } from "../../site/utils"

const TeamListing = () => {
  //const [parent] = useAutoAnimate({ duration: 300 })

  const data = useStaticQuery(graphql`
    query GetTeamLandingData {
      glstrapi {
        teamCategories {
          id
          label
          url
          teams(where: {publish: true}, sort: "sort:asc") {
            id
            imagetransforms
            tile_image {
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 240
                    width: 208
                    layout: FIXED
                    quality: 90
                    transformOptions: { cropFocus: NORTH, fit: COVER }
                  )
                }
              }
            }
            slug
            name
            mobile_no
            designation
            email
          }
        }
        teams(where: {publish: true}) {
          id
          imagetransforms
          tile_image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  height: 240
                  width: 208
                  layout: FIXED
                  quality: 90
                  transformOptions: { cropFocus: NORTH, fit: COVER }
                )
              }
            }
          }
          slug
          name
          mobile_no
          designation
          email
          sort
        }
      }
    }
  `)

  const teamCategories = data.glstrapi.teamCategories

  const { isMobile, isTabletOnly, isCustomMax } = useDeviceMedia({
    max: "1399px",
  })

  var allCategoryOption = {
    label: "All Departments",
    value: "all",
  }


  const [search, setSearch] = useState("")
  const location = useLocation()
  const searchParams = parseSearch(location.search)
  const department = searchParams.department;
  const allTeams = data.glstrapi.teams

  const selectOptions = [
    allCategoryOption,
    ...teamCategories
      .map(category => {
        if (_.isEmpty(category.teams)) return
        else
          return {
            label: category.label,
            value: category.url,
          }
      })
      .filter(option => option),
  ]

  const SortTeams = allTeams.sort((a, b) => sort(a.sort, b.sort))
  var initTeamList = SortTeams;
  if (department) {
    initTeamList = teamCategories.filter(
      teamCategory => department === teamCategory.url
    )[0]?.teams;
    allCategoryOption = {
      label: "Commercial",
      value: "commercial",
    }
  }



  // Category tab logic
  const [selectedTeams, setSelectedTeams] = useState(initTeamList)
  const [selectedOption, setSelectedOption] = useState(allCategoryOption)

  useEffect(() => {
    setCurrentPage(1);
    if (sessionStorage.getItem('setInitialValue') != null) {
      sessionStorage.setItem('setInitialValue', sessionStorage.getItem('setInitialValue'));
      setCurrentPage(sessionStorage.getItem('setInitialValue'))
    } else {
      sessionStorage.setItem('setInitialValue', 1);
    }
  }, [selectedTeams])

  const handleTabChange = option => {
    setSelectedOption(option);
    if (option.value === "all") {
      setSelectedTeams(SortTeams)
      return
    }
    var filteredTeams = teamCategories.filter(
      teamCategory => option.value === teamCategory.url
    )[0]?.teams;

    if (search) {
      filteredTeams = filteredTeams.filter(team =>
        team.name.toLowerCase().slice(0, search.length) === search
      );
    }
    setSelectedTeams(filteredTeams)
  }
  // Category tab logic

  const handleChange = val => {
    setSearch(val.target.value)
    let listFilter = SortTeams;
    if (selectedOption.value !== "all") {
      listFilter = teamCategories.filter(
        teamCategory => selectedOption.value === teamCategory.url
      )[0]?.teams;
    }

    if (val.target.value) {
      var listTeam = listFilter.filter(team =>
        team.name.toLowerCase().slice(0, val.target.value.length) === val.target.value.toLowerCase()
      );
      setSelectedTeams(listTeam);
    } else {
      setSelectedTeams(listFilter)
    }
  }

  // for pagination
  const itemsPerPage = isMobile ? 12 : isTabletOnly ? 21 : isCustomMax ? 20 : 24
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedTeams,
    itemsPerPage,
  })
  // for pagination

  if (!currentItems) return null

  return (
    <div className="team-listing-wrap">
      <Container className="team-listing-container">
        <div className="category-container">
          <div className="search-box">
            <input
              className="form-control search"
              placeholder="Search by name"
              onChange={handleChange}

            />
            <i className="icon-search-icon" />
          </div>

          <Select
            options={selectOptions}
            isSearchable={false}
            className={"category-select"}
            classNamePrefix={"category-select"}
            //   hideSelectedOptions={true}
            value={selectedOption}
            onChange={handleTabChange}
          />

          {/* {isTablet ? (
            
          ) : (
            <div className="category-tab-section">
              {selectOptions?.map(option => (
                <button
                  className={`category-tab button button-primary ${
                    selectedOption.value === option.value ? "active" : ""
                  }`}
                  onClick={() => handleTabChange(option)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )} */}
        </div>
        <div className="teams-section">
          <div className="team-grid">
            {currentItems?.map(team => (
              <TeamCard team={team} key={team.id} />
            ))}
          </div>
          {currentItems.length == 0 &&
            <p className="norecord">No records</p>
          }
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={selectedTeams.length}
            setCurrentPage={setCurrentPage}
            selectOptions={selectOptions}
            allCategoryOption={allCategoryOption}
            teamCategories={teamCategories}
          />
        </div>
      </Container>
    </div>
  )
}

export default TeamListing
