import { Link } from "gatsby"
import React, { useState } from "react"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import "./VideoListing.scss"
import { format } from "date-fns"
import PlayVideo from "../PlayVideo/PlayVideo"

const VideoCard = ({ videos }) => {
  const [isPlay, setPlay] = useState(false)
  if (!videos) return null

  const postedDate = format(new Date(videos.date), "do MMM yyyy")

  return (
    <div className="videos-card-wrap">
      <div className="videos-card-img">
        {videos.video_url && (
          <a
            className="btn-play icon icon-play"
            onClick={() => setPlay(true)}
          ></a>
        )}
        <GetGatsbyImage
          image={videos.thumbnail}
          alt="videos - img"
          className="videos-img"
        />
      </div>

      <div className="videos-content">
        <Link className="videos-title">{videos?.title}</Link>
        <div className="videos-details">
          <p className="videos-date">{postedDate}</p>
          <div className="slant-divider"></div>
          <p className="videos-category">{videos?.video_category?.title}</p>
        </div>
      </div>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={videos.video_url}
          htmlink={""}
        />
      )}
    </div>
  )
}

export default VideoCard
