import { format } from "date-fns"
import { Link } from "gatsby"
import React from "react"
import { newsPageURL } from "../../site/urls"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import "./NewsCard.scss"

const NewsCardFeatured = ({ news }) => {
  if (!news) return null

  const postedDate = format(new Date(news.post_on), "do MMM yyyy")

  return (
    <div className="featured-card-wrap">
      <Link
        to={newsPageURL + news.slug + "/"}
        className="img-zoom featured-img-section"
      >
        <GetGatsbyImage
          image={news.tile_image}
          alt="news - img"
          className="featured-img"
        />
        <p className="featured-tag">featured</p>
      </Link>
      <div className="featured-content-wrap">
        <div className="featured-content">
          <h3 className="title">{news.title}</h3>
          <div className="details">
            <p className="date">{postedDate}</p>
            {news.blog_category && (
              <>
                <div className="slant-divider"></div>
                <p className="category">{news.blog_category?.name}</p>
              </>
            )}
          </div>
          <p className="short-description">{news.short_description}</p>
        </div>
        <div className="news-cta">
          <Link
            to={newsPageURL + news.slug + "/"}
            className="button button-secondary"
          >
            view article
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NewsCardFeatured
