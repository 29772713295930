import _ from "lodash"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import ReactSelect from "react-select"
import Slider from "react-slick"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import PlayVideo from "../PlayVideo/PlayVideo"
import "./ReviewsModule.scss"
import noImg from "../../images/no-image.png"

const ReviewsModule = ({ module }) => {
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const VideoReviewItem = ({ review }) => {
    const [isPlay, setPlay] = useState(false)
    return (
      <div className="video-review-item-wrap">
        {review.image ? <GetGatsbyImage
          image={review.image}
          fallbackalt="video-review-image"
          className="video-img"
        /> : <img src={noImg} alt="video-review-image" className="video-img" />}
        <a
          className="btn-play icon icon-play"
          onClick={() => setPlay(true)}
        ></a>
        {isPlay && (
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setPlay}
            videoId=""
            isCloseFunction={setPlay}
            videourl={review.embed_video_url}
            htmlink={""}
          />
        )}
      </div>
    )
  }

  return (
    <div className="review-module-wrapper">
      <Container className="review-module-container">
        {!_.isEmpty(module?.video_reviews) && (
          <Slider className="video-reviews-section" {...settings}>
            {module?.video_reviews.map(review => (
              <VideoReviewItem review={review} />
            ))}
          </Slider>
        )}
      </Container>
      {module.heading || module.title &&
      <div className="google-reviews-wrapper section-p">
        <Container className="google-reviews-container">
          <div className="text-content">
            <h4 className="heading">{module.heading}</h4>
            <h3 className="title">{module.title}</h3>
          </div>
        </Container>
      </div>}
    </div>
  )
}

export default ReviewsModule
