import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/Seo/seo"

import "../scss/global.scss"
import CommonTemplate from "./common-template"

function DefaultTemplate(props) {
  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage?.add_components
  const locationState = props.location.state

  return (
    <>
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
        image={GQLPage?.banner_section?.banner_image?.url_sharp?.childImageSharp ? GQLPage.banner_section.banner_image.url_sharp.childImageSharp.resize : null}
      />
      <CommonTemplate
        GQLPage={GQLPage}
        GQLModules={GQLModules}
        locationState={locationState}
      />
    </>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query GetPage($id: ID!) {
    glstrapi {
      page(id: $id) {
        alias
        seo_meta {
          title
          keywords
          description
          id
        }
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        banner_section {
          banner_image {
            url
            url_sharp {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
          content
          id
          show_reviews
          show_search
          title
          ctas {
            custom_link
            id
            label
            theme
            target
            menu_item {
              label
              id
              slug
              parent {
                label
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
          }
        }
        choose_form
        id
        layout
        menu {
          slug
          label
          id
          parent {
            label
            slug
          }
        }
        page_class
        publish
        Popular_Search
        popular_searches {
          id
        }
        title
        imagetransforms

        add_components {
          ... on GLSTRAPI_ComponentModulesAboutAndTeamsModule {
            __typename
            id
            heading
            module_content
            teams {
              tile_image {
                url
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 240
                      width: 208
                      layout: FIXED
                      quality: 90
                      transformOptions: { cropFocus: NORTH, fit: COVER }
                    )
                  }
                }
              }
              slug
              name
              mobile_no
              designation
              email
              sort
            }
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesNewsListing {
            __typename
            id
            show_newsletter

            featured_blogs {
              allNews
              id
              featured_blog {
                title
                post_on
                id
                blog_category {
                  name
                  slug
                  id
                }
                short_description
                tile_image {
                  url
                  url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        height: 280
                        width: 448
                        layout: FIXED
                        quality: 90
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
                }
                slug
              }
              category_name
              blog_category {
                slug
                name
                id
              }
            }
          }

          ... on GLSTRAPI_ComponentBlocksRichText {
            __typename
            id
            text_content
          }

          ... on GLSTRAPI_ComponentModulesReviewsModule {
            id
            __typename
            heading
            title
            video_reviews {
              embed_video_url
              id
              image {
                url
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 400
                      width: 448
                      layout: FIXED
                      quality: 90
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsUserfulLinksModule {
            __typename
            id
            title
            heading
            link_items {
              id
              description
              image {
                url
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 400
                      width: 448
                      layout: FIXED
                      quality: 90
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              cta {
                custom_link
                id
                label
                theme
                target
                menu_item {
                  label
                  id
                  slug
                  parent {
                    label
                    slug
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesBookAValuation {
            __typename
            id
            content
            heading
            title
            icon_descriptions {
              stat_text
              stat_description
              id
              icon_class
            }
          }

          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            __typename
            id
            choose_collection
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            description
            title
            heading
            theme
          }

          ... on GLSTRAPI_ComponentModulesSelectModule {
            __typename
            id
            module_content
            module
            title
          }

          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            title
            heading
            faq {
              question
              id
              answer
            }
          }

          ... on GLSTRAPI_ComponentModulesStatsBanner {
            __typename
            id
            content
            heading
            stats {
              stat_description
              stat_text
              icon_class
              id
            }
          }

          ... on GLSTRAPI_ComponentModulesTeamSlider {
            __typename
            id
            showAllTeam
            heading
            title
            team_category {
              id
              label
              url
              teams {
                tile_image {
                  url
                  url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        height: 240
                        width: 208
                        layout: FIXED
                        quality: 90
                        transformOptions: { cropFocus: NORTH, fit: COVER }
                      )
                    }
                  }
                }
                slug
                name
                sort
                mobile_no
                designation
                email
              }
            }
            team_speciality {
              id
              label
              url
              teams {
                tile_image {
                  url
                  url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        height: 240
                        width: 208
                        layout: FIXED
                        quality: 90
                        transformOptions: { cropFocus: NORTH, fit: COVER }
                      )
                    }
                  }
                }
                slug
                name
                sort
                mobile_no
                designation
                email
              }
            }
          }

          ... on GLSTRAPI_ComponentModulesTileBlock {
            __typename
            id
            _id
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            flag
            tile_block_content
            title
            video_url
            tile_image {
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 580
                    width: 568
                    layout: FIXED
                    quality: 90
                  )
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesHomeTileBlock {
            __typename
            id
            _id
            ctas {
              custom_link
              id
              label
              target
              theme
              menu_item {
                link_type
                slug
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
              }
            }
            title
            content
            tile_flag
            Video_URL
            home_tile_image {
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 480
                    width: 688
                    layout: FIXED
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
