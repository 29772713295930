import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "./UsefulLinksModule.scss"
import _ from "lodash"
import TeamCard from "../TeamCard/TeamCard"
import { GetPageURL } from "../common/site/utils"

const UsefulLinksModule = ({ module }) => {
  // Slider settings
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  const items = module?.link_items

  return (
    <section className="usefull-link-wrapper section-p">
      <Container>
        <Row>
          <Col className="text-center">
            <h4 className="small-header-text">{module?.title}</h4>
            <h2>{module?.heading}</h2>
            {!_.isEmpty(items) && (
              <Slider className="usefull-link-slider" {...settings}>
                {items.map(item => (
                  <div className="usefull-link-card-wrap">
                    <Link
                      to={GetPageURL(item.cta.menu_item, item.cta.custom_link)}
                    >
                      <img src={item.image.url} alt="useful links image" className="item-img" />
                    </Link>
                    <div className="content-section">
                      <div className="cta-section">
                        <Link
                          to={GetPageURL(
                            item.cta.menu_item,
                            item.cta.custom_link
                          )}
                          className="cta-link"
                        >
                          {item.cta.label}
                        </Link>
                        <i className="icon icon-email-arrow"></i>
                      </div>
                      <p className="description">{item.description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default UsefulLinksModule
