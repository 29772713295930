import React from "react"
import "./AboutTeamModule.scss"
import parse from "html-react-parser"
import { Link } from "gatsby"
import { GetPageURL } from "../common/site/utils"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import TeamCard from "../TeamCard/TeamCard"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { sort } from "../../site/utils"

const AboutTeamModule = ({ module }) => {
  const array = [...module?.teams?.sort((a,b) => sort(a.sort, b.sort))]
  const { isTablet } = useDeviceMedia()

  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className="about-team-wrap section-p">
      <Container className="about-team-container">
        <div className="about-section">
          <h4 className="title">{module.heading}</h4>
          {module.module_content && (
            <div className="content">{parse(module.module_content)}</div>
          )}
          <div className="cta-section">
            {module.ctas?.map(cta => (
              <Link
                to={GetPageURL(cta.menu_item, cta.custom_link)}
                className={`button button-${cta.theme}`}
              >
                {cta.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="team-section">
          {isTablet ? (
            <Slider className="about-team-slider" {...settings}>
              {array.map(team => (
                <TeamCard team={team} hideContact />
              ))}
            </Slider>
          ) : (
            <div className="team-grid">
              {module.teams?.map(team => (
                <TeamCard team={team} hideContact />
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default AboutTeamModule
