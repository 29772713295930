import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { GetPageURL } from "../common/site/utils"
import "./HomeTileBlockModule.scss"
import PlayVideo from "../PlayVideo/PlayVideo"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import _ from "lodash"

const HomeTileBlockModule = ({ module }) => {
  const [isPlay, setPlay] = useState(false)
  if (!module) return null

  return (
    <div className={`home-tile-block-module-wrapper section-m`}>
      <Container className={`home-tile-block-container`}>
        <div className="img-block rounded">
          <GetGatsbyImage
            image={module.home_tile_image}
            fallbackalt={module.title + " - img"}
          />
          {module.tile_flag && <div className="tag">{module.tile_flag}</div>}
          {module.Video_URL && (
            <a
              className="btn-play icon icon-play"
              onClick={() => setPlay(true)}
            ></a>
          )}
        </div>
        <div className="content">
          <h4 className="title">{module.title}</h4>
          {module.content && (
            <div className="description">
              {parse(module.content)}
            </div>
          )}
          {!_.isEmpty(module.ctas) && (
            <div className="cta-section">
              {module.ctas.map(cta =>  {
                if (cta.custom_link?.includes('http')) {
                  return (
                    <a
                      target={cta.target === "new_window" ? "_blank" : ""}
                      href={cta?.custom_link}
                      className={`button button-${cta.theme}`}
                    >
                    {cta.label}
                  </a>
                  )
                }
                return (
                    <Link
                      target={cta.target === "new_window" ? "_blank" : ""}
                      to={GetPageURL(cta?.menu_item, cta?.custom_link)}
                      className={`button button-${cta.theme}`}
                    >
                    {cta.label}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </Container>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={module.Video_URL}
          htmlink={""}
        />
      )}
    </div>
  )
}

export default HomeTileBlockModule
