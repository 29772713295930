import React from "react"
import "./BannerLanding.scss"
import parse from "html-react-parser"
import linesBg from "../../images/lines-bg.svg"
import { Container } from "react-bootstrap"
import starsImg from "../../images/icons/review-stars.svg"
import googleLogo from "../../images/icons/icon-google.svg"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const BannerLanding = ({ bannerSection }) => {
  const { isMobile } = useDeviceMedia()

  return (
    <div className="banner-landing-wrap">
      <img src={linesBg} className="lines-img" />
      <Container className="banner-landing-container">
        <h4 className="title">{bannerSection.title}</h4>
        {bannerSection?.content && (
          <div className="banner-content">{parse(bannerSection?.content)}</div>
        )}
        {bannerSection.show_reviews && (
          <div className="review-section">
            <div className="review">
              <img src={starsImg} alt={bannerSection.title + "- White & Co Real Estate"} className="stars-img" />
              <p className="review-count">
                <strong>4.9/5</strong>
                {!isMobile && " from 460 Reviews "}
              </p>
            </div>
            <div className="divider"></div>
            <img src={googleLogo} alt="google-logo" className="google-img" />
          </div>
        )}
      </Container>
    </div>
  )
}

export default BannerLanding
