import { Link } from "gatsby"
import React from "react"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import "./NewsCard.scss"
import { format } from "date-fns"
import { newsPageURL } from "../../site/urls"

const NewsCard = ({ news }) => {
  if (!news) return null

  const postedDate = format(new Date(news.post_on), "do MMM yyyy")

  return (
    <div className="news-card-wrap">
      <Link to={newsPageURL + news.slug + "/"} className="img-zoom">
        <GetGatsbyImage
          image={news.tile_image}
          alt="news - img"
          className="news-img"
        />
      </Link>
      <div className="news-content">
        <Link to={newsPageURL + news.slug + "/"} className="news-title">
          {news.title}
        </Link>
        <div className="news-details">
          <p className="news-date">{postedDate}</p>
          {news.blog_category && (
            <>
              <div className="slant-divider"></div>
              <p className="news-category">{news.blog_category?.name}</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewsCard
