import { Link } from "gatsby"
import React from "react"
import { areaguidesPageURL } from "../../site/urls"
import noImg from "../../images/no-image.png"
import "./AreaguideCard.scss"

const AreaguideCard = ({ areaguide }) => {
  if (!areaguide) return null

  return (
    <div className="areaguide-card-wrap">
      <Link to={areaguidesPageURL + areaguide.slug + "/"} className="img-zoom">
        {areaguide.tile_image ? (
          <img src={areaguide.tile_image.url} alt={areaguide.name + " - White & Co Real Estate"} className="card-img" />
        ) : (
          <img src={noImg} alt={areaguide.name + " - White & Co Real Estate"} className="card-img" />
        )}
      </Link>
      <div className="content">
        <Link to={areaguidesPageURL + areaguide.slug + "/"} className="name">
          {areaguide.name}
        </Link>
        <p className="description">{areaguide.short_description}</p>
      </div>
    </div>
  )
}

export default AreaguideCard
